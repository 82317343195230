<template>
  <img
    class="heart"
    alt="Love"
    src="./assets/heart_800.png"
    srcset="
      ./assets/heart_800.png   800w,
      ./assets/heart_1200.png 1200w,
      ./assets/heart_2400.png 2400w
    "
  />
  <div class="mask-content">
    <h1>Kees Broersen</h1>
    <h2>
      <span :class="{ 'is-hovering': hover.span1 }">Front-end</span>
      <span :class="{ 'is-hovering': hover.span2 }">Design</span>
      <span :class="{ 'is-hovering': hover.span3 }">Music</span>
    </h2>
    <p>
      <span class="socials">
        <a
          href="https://www.linkedin.com/in/kees-broersen-69b95965/"
          class="linkedin"
          target="_blank"
        >
          <AppIcon type="linkedin" />
        </a>
        <a
          href="https://www.instagram.com/keesbroersen.nl/"
          class="instagram"
          target="_blank"
        >
          <AppIcon type="instagram" />
        </a>
      </span>
      <a href="mailto:info@keesbroersen.nl" target="_blank"
        >info@keesbroersen.nl</a
      ><span>KVK: 62002147</span>
      <span>Hoogkarspel</span>
    </p>
  </div>
  <div class="content">
    <h1>Kees Broersen</h1>
    <h2>
      <span :class="{ 'is-hovering': hover.span1 }">Front-end</span>
      <span :class="{ 'is-hovering': hover.span2 }">Design</span>
      <span :class="{ 'is-hovering': hover.span3 }">Music</span>
    </h2>
    <p>
      <span class="socials">
        <a
          href="https://www.linkedin.com/in/kees-broersen-69b95965/"
          class="linkedin"
          target="_blank"
        >
          <AppIcon type="linkedin" />
        </a>
        <a
          href="https://www.instagram.com/keesbroersen.nl/"
          class="instagram"
          target="_blank"
        >
          <AppIcon type="instagram" />
        </a>
      </span>
      <a href="mailto:info@keesbroersen.nl" target="_blank"
        >info@keesbroersen.nl</a
      >
      <span>KVK: 62002147</span>
      <span>Hoogkarspel</span>
    </p>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import AppIcon from "./components/AppIcon.vue";

const hover = reactive({
  span1: false,
  span2: false,
  span3: false,
});

// const onHover = (item) => {
//   for (const property in hover) {
//     hover[property] = false;
//   }
//   hover[item] = true;
// };
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: linear-gradient(150deg, #000 0%, #151515 100%);
}

.heart {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: -260px;
  transform: translate(0%, -50%);
  height: 100%;
  max-height: 75vh;
  width: auto;
  aspect-ratio: 1;

  @media screen and (min-width: 768px) {
    max-height: 95vh;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

h1 {
  font-size: 24px;

  @media screen and (min-width: 768px) {
    font-size: 32px;
  }
}

h2 {
  display: flex;
  flex-direction: column;
  font-size: 52px;
  text-align: right;

  @media screen and (min-width: 768px) {
    font-size: 80px;
  }

  span {
    transition: all 0.2s ease-out;
    animation: bounce 1s ease-in-out;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    animation-delay: 2.5s;

    &:nth-child(2) {
      animation-delay: 2.6s;
    }
    &:nth-child(3) {
      animation-delay: 2.7s;
    }

    &.is-hovering {
      transform: translateX(-50px);
    }
  }
}

p {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    font-size: 16px;
  }
}

.linkedin,
.instagram {
  display: flex;

  svg {
    max-width: 32px;
    width: 100%;
    color: #d9a9e1;

    @media screen and (max-width: 767px) {
      color: #a23cdd;
    }
  }
}

.content {
  h1,
  h2 span,
  p {
    color: transparent;
    background-clip: text;
  }

  a {
    color: transparent;
  }

  h1 {
    background-image: linear-gradient(
      89.63deg,
      #e846a2 -5.82%,
      #8f2383 102.95%
    );
  }

  h2 {
    span:nth-child(1) {
      background-image: linear-gradient(171deg, #ba139b 0%, #a61299 100%);
    }

    span:nth-child(2) {
      background-image: linear-gradient(171deg, #9c1198 0%, #881095 100%);
    }

    span:nth-child(3) {
      background-image: linear-gradient(171deg, #7d0f94 0%, #6b0e92 100%);
    }
  }

  p {
    background-image: linear-gradient(89.63deg, #d9a9e1 0%, #9c22ce 100%);
  }
}

.socials {
  display: flex;
  gap: 16px;

  @media screen and (max-width: 767px) {
    position: absolute;
    left: 32px;
    bottom: 32px;
  }
}

.content,
.mask-content {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  width: 100vw;
  padding: 32px;

  @media screen and (min-width: 768px) {
    padding: 60px;
  }
}

.mask-content {
  z-index: 2;
  mask: url("./assets/heart_800.png");
  mask-size: 75vh;
  mask-position: -260px 50%;
  mask-repeat: no-repeat;
  color: #000;

  @media screen and (min-width: 768px) {
    mask-size: 95vh;
    mask-position: center;
  }
}

#app {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  min-height: 100dvh;
  width: 100%;

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  text-transform: uppercase;

  > * {
    filter: blur(50px);
    animation: deblur 2s forwards;
    animation-delay: 1s;
  }
}

@keyframes deblur {
  0% {
    filter: blur(50px);
  }
  100% {
    filter: blur(0px);
  }
}

@keyframes tickticktick {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateX(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateX(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateX(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateX(0);
  }
  57% {
    transform: scale(1, 1) translateX(-7px);
  }
  64% {
    transform: scale(1, 1) translateX(0);
  }
  100% {
    transform: scale(1, 1) translateX(0);
  }
}
</style>
